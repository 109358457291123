<template>
  <v-card rounded="0">
    <v-card-title class="text-h5 text-uppercase panelheader">
      {{ tLabel("Gate-out") }}
      <v-spacer />
      <v-icon large @click="discardGateOut">mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <v-form ref="gateOutForm" v-model="valid">
        {{ gateSubmitReqObj.message }}

        <v-row align="center" v-for="unit in gateSubmitReqObj.units" :key="unit.visitId" class="blue lighten-5 my-2">
          <v-col cols="3" class="text-h4 text-uppercase font-weight-medium">
            {{ unit.objectCode }}
          </v-col>
          <v-col cols="2" v-if="isGoodsByItem(unit)" class="text-right">
            <v-text-field  :label="tLabel('Quantity')" v-model="unit.quantity" />
          </v-col>
          <v-col cols="2" v-if="isGoodsByItem(unit)" class="text-left"> / {{ unit.initialQuantity }} </v-col>
          <v-col cols="2" v-if="isGoodsByLastMove(unit)" class="text-uppercase">
            <v-checkbox :label="tLabel('Svuotamento finale')" v-model="unit.isCompleted" />
          </v-col>
        </v-row>

        <v-row align="center" class="my-0 py-0">
          <v-col cols="3" class="my-0 py-0">
            <v-text-field
              :label="tLabel('Order')"
              v-model="gateSubmitReqObj.handlingOutOrderCode"
              @input="(val) => (gateSubmitReqObj.handlingOutOrderCode = val ? val.toUpperCase() : null)"
            />
          </v-col>
        </v-row>

        <v-row v-if="gateSubmitReqObj.units[0].sysType === 'UNT' || gateSubmitReqObj.units[0].sysType === 'GOO'">
          <v-col cols="12">
            <v-expansion-panels v-model="damagePanel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
                  {{ tLabel("Damages") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="5">
                      <v-autocomplete
                        :label="tLabelRequired('Damage Type')"
                        :items="damageTypes"
                        clearable
                        v-model="damageToAdd.damageType"
                        :item-text="damageTypeRender"
                        return-object
                      />
                    </v-col>
                    <v-col cols="5">
                      <v-text-field :label="tLabel('Position')" v-model="damageToAdd.position" @input="(val) => (damageToAdd.position = val ? val.toUpperCase() : null)" />
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="10">
                      <v-textarea :label="tLabel('Remarks')" v-model="damageToAdd.remarks" counter maxlength="255" />
                    </v-col>
                    <v-col cols="2" class="text-center">
                      <v-btn color="primary" :disabled="damageToAdd.damageType == null" @click="addDamage"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="12">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="header">
                            <tr>
                              <th />
                              <th class="text-left">{{ tLabel("Type") }}</th>
                              <th class="text-left">{{ tLabel("Position") }}</th>
                              <th class="text-left">{{ tLabel("Remarks") }}</th>
                            </tr>
                          </thead>
                          <tbody v-if="gateSubmitReqObj.units.length > 0 && gateSubmitReqObj.units[0].damages.length > 0">
                            <tr v-for="damage in gateSubmitReqObj.units[0].damages" :key="damage.damageType.id + damage.position">
                              <td>
                                <v-btn icon color="primary" @click="removeDamage(damage)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </td>
                              <td>{{ damage.damageType.code }} - {{ damage.damageType.description }}</td>
                              <td>{{ damage.position }}</td>
                              <td>{{ damage.remarks }}</td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="4">{{ tLabel("No damages") }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-uppercase font-weight-bold">
                <span>
                  <v-icon large>mdi-truck</v-icon>
                  <span class="mx-2 px-2">{{ tLabel("Transport Vehicle") }}</span>
                </span>
              </v-card-title>
              <v-divider class="mx-4 warning"></v-divider>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="4">
                    <v-autocomplete
                      v-if="!gateSubmitReqObj.isRoro"
                      :label="tLabelRequired('License Plate')"
                      :items="listTruckPlates"
                      item-text="licensePlate"
                      return-object
                      clearable
                      v-model="handlingOutTruckInternal"
                      @change="onChangeLicensePlate"
                    />
                    <v-text-field
                      v-else
                      :label="tLabelRequired('License Plate')"
                      v-model="gateSubmitReqObj.handlingOutTruckLicensePlate"
                      :readonly="true"
                      @input="(val) => (gateSubmitReqObj.handlingOutTruckLicensePlate = val ? val.toUpperCase() : null)"
                    />
                  </v-col>

                  <v-col cols="4">
                    <v-autocomplete
                      :label="tLabel('Transport Company')"
                      v-model="gateSubmitReqObj.handlingOutTruckOwnerCompanyId"
                      :items="carriers"
                      item-value="id"
                      item-text="code"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="12">
            <ItineraryCard :itineraryVisit="gateSubmitReqObj.itineraryVisit" />
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="12">
            <v-textarea :label="tLabel('Remarks')" v-model="gateSubmitReqObj.handlingOutRemarks" counter maxlength="255" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn medium outlined class="pa-2 ma-2" @click="discardGateOut"> <v-icon class="mr-2">mdi-cancel</v-icon>{{ tLabel("Cancel") }} </v-btn>
      <v-btn color="primary" class="pa-2 ma-2" @click="doGateOut(false)" medium> <v-icon class="mr-2">mdi-logout-variant</v-icon>{{ tLabel("Gate-out") }} </v-btn>
      <v-btn color="primary" class="pa-2 ma-2" @click="doGateOut(true)" medium> <v-icon class="mr-2">mdi-printer-outline</v-icon>{{ tLabel("Gate-out + Stampa") }} </v-btn>
    </v-card-actions>

    <v-dialog v-model="confirmDialogVisible" persistent max-width="350" header="Conferma">
      <v-card>
        <v-card-text>
          {{ confirmMessage }}

          <v-col v-if="listTruckPlatesAssociated != null">
            <v-autocomplete
              v-if="!gateSubmitReqObj.isRoro"
              :label="tLabelRequired('List License Plates Associated ')"
              :items="listTruckPlatesAssociated"
              item-text="licensePlate"
              return-object
              clearable
              v-model="hOutTruckAssociatedSelected"
              @change="onChangeLicensePlateAssociated"
            />
            <v-text-field
              v-else
              :label="tLabel('License Plate')"
              v-model="gateSubmitReqObj.handlingOutTruckLicensePlate"
              :readonly="true"
              @input="(val) => (gateSubmitReqObj.handlingOutTruckLicensePlate = val ? val.toUpperCase() : null)"
            />
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onConfirm" small>Si</v-btn>
          <v-btn @click="confirmDialogVisible = false" small>No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ItineraryCard from "@/components/yard/ItineraryCard";
import ToastMixin from "@/mixins/ToastMixin";

export default {
  name: "GateOut",
  data() {
    return {
      valid: true,
      locodes: null,
      departureLocode: null,
      departureCompanyId: null,
      destinationLocode: null,
      destinationCompanyId: null,
      podLocode: null,
      spodLocode: null,
      polLocode: null,
      damageTypes: [],
      damageToAdd: {},
      damagePanel: [],
      itineraryVisit: {},
      carriers: [],
      allCompanies: [],
      handlingOutTruckInternal: [],
      hOutTruckAssociatedSelected: [],

      confirmMethod: null,
      confirmData: null,
      confirmMessage: null,
      confirmDialogVisible: null,
    };
  },
  props: {
    gateSubmitReqObj: {
      type: Object,
    },
    listTruckPlates: {
      type: Array,
    },
    listTruckPlatesAssociated: {
      type: Array,
    },
    handlingOutTruck: {
      type: Object,
    },
  },
  components: { ItineraryCard },
  mixins: [ToastMixin],
  watch: {
    handlingOutTruck: function () {
      this.handlingOutTruckInternal = this.handlingOutTruck;
    },
  },
  async mounted() {
    this.locodes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "locodes/list");
    this.damageTypes = await this.$api.yardData.damageTypes();
    this.carriers = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/carriers");
    this.allCompanies = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/all");
    this.handlingOutTruckInternal = this.handlingOutTruck;
  },
  methods: {
    onChangeLicensePlate(event) {
      if (event) {
        console.log("onChangeLicensePlate", this.handlingOutTruckInternal);
        this.gateSubmitReqObj.handlingOutTruckLicensePlate = this.handlingOutTruckInternal.licensePlate;
        this.gateSubmitReqObj.handlingOutTruckOwnerCompanyId = this.handlingOutTruckInternal.ownerCompanyId;
        this.gateSubmitReqObj.handlingOutIdTruck = this.handlingOutTruckInternal.id;
        console.log("gateSubmitReqObj", this.gateSubmitReqObj);
        console.log("handlingOutTruck", this.handlingOutTruck);
        console.log("systipe:", this.gateSubmitReqObj.item.sysType);
        if (this.gateSubmitReqObj.item.sysType === "UNT") {
          const resp = this.$api.yardData.checkPickUpCarrier(this.gateSubmitReqObj).then((response) => {
            console.log("response:", response);
            console.log("success:", response.success);
            if (response.success == false) {
              // this.showError(response.message);
              this.confirmMessage = response.message;
              this.confirmMethod = "replaceCarrierGateOut";
              this.confirmData = this.gateSubmitReqObj;

              this.doConfirm(this.confirmMessage, this.confirmMethod, this.confirmData);
            }
          });
        } else if (this.gateSubmitReqObj.item.sysType === "GOO") {
          console.log("DENTRO");
          const resp = this.$api.yardData.checkPickUpCarrierForGoods(this.gateSubmitReqObj).then((response) => {
            console.log("response:", response);
            console.log("success:", response.success);
            if (response.success == false) {
              // this.showError(response.message);
              this.confirmMessage = response.message;
              this.confirmMethod = "replaceCarrierGateOut";
              this.confirmData = this.gateSubmitReqObj;

              this.doConfirm(this.confirmMessage, this.confirmMethod, this.confirmData);
            }
          });
        }
      }
    },

    onChangeLicensePlateAssociated(event) {
      console.log("hOutTruckAssociatedSelected", this.hOutTruckAssociatedSelected);
    },

    async replaceCarrierGateOut() {
      console.log("Elimina HandligCarrier della unit associato al mezzo di trasporto selezionato");
      console.log("ID: ", this.hOutTruckAssociatedSelected.id);
      this.gateSubmitReqObj.replaceCarrierId = this.hOutTruckAssociatedSelected.id;
      console.log("ID RE: ", this.gateSubmitReqObj.replaceCarrierId);
      const handlingId = await this.$api.yardData.replaceCarrierGateOut(this.gateSubmitReqObj);
    },

    async doGateOut(print) {
      const valid = this.$refs.gateOutForm.validate();
      if (!valid) {
        return;
      }
      console.log("this.gateSubmitReqObj", this.gateSubmitReqObj);
      if (this.gateSubmitReqObj.handlingOutTruckLicensePlate == null) {
        this.gateSubmitReqObj.message = "Targa mezzo non valorizzata";
        this.showError("Targa mezzo non valorizzata");
        return;
      }
      try {
        const handlingId = await this.$api.yardData.gateOut(this.gateSubmitReqObj);
        console.log("GateOut handlingId;", handlingId);
        if (print) this.stampaInterchange(this.gateSubmitReqObj.units[0].visitId, handlingId);
        this.damagePanel = [];
        this.$refs.gateOutForm.reset();
        this.$eventBus.$emit("gateOutDone", true);
      } catch (e) {
        const msg = e.detailMessage ? e.detailMessage : e.message;
        this.showError("Errore gate out: " + msg);
        console.log(e);
      }
    },

    discardGateOut() {
      this.damagePanel = [];
      this.$refs.gateOutForm.reset();
      this.$eventBus.$emit("gateOutDone", false);
    },

    doConfirm(confirmMessage, confirmMethod, confirmData) {
      this.confirmMessage = confirmMessage;
      this.confirmData = confirmData;
      this.confirmMethod = confirmMethod;
      this.confirmDialogVisible = true;
    },

    onConfirm() {
      this.confirmDialogVisible = false;
      let method = this[this.confirmMethod];
      if (method == null) {
        console.warn("Confirm method null:", this.confirmMethod);
        return;
      }
      method(this.confirmData);
    },

    async stampaInterchange(visitId, handlingId) {
      try {
        const _callConfig = {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
            Accept: "*/*",
            Pragma: "no-cache",
          },
        };
        const url =
          handlingId != null
            ? `${this.$apiConfiguration.BASE_PATH_YARD}reports/interchangeOutByHandlingId/` + handlingId
            : `${this.$apiConfiguration.BASE_PATH_YARD}reports/interchangeOutByVisitId/` + visitId;
        console.log("URL Stampa; ", url);
        const response = await this.$api.get(url, _callConfig);
        const blob = new Blob([response], { type: "application/pdf;charset=ANSI" });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute("download", "Interchange3.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        console.error("Errore Interchange", e);
      }
    },
    isGoodsByItem(unit) {
      return unit.sysType == "GOO" && unit.countType == "I";
    },
    isGoodsByLastMove(unit) {
      return unit.sysType == "GOO" && unit.countType == "L";
    },
    damageRendered() {
      return this.gateSubmitReqObj.units[0].sysType === "UNT";
    },
    damageTypeRender: (item) => item.code + " - " + item.description,
    addDamage() {
      this.gateSubmitReqObj.units[0].damages.push(JSON.parse(JSON.stringify(this.damageToAdd)));
      this.damageToAdd = {};
    },
    removeDamage(damage) {
      const damages = this.gateSubmitReqObj.units[0].damages;
      damages.splice(damages.indexOf(damage), 1);
    },
  },
};
</script>
